
.product-editor__name{
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 10px;
}


.product-editor__price{
    font-size: 16px;
    font-weight: 500;
    color: #1e1e1e;
}
