.theme-container {
    display: flex;
}

.theme-container .theme-3-sidebar .theme-3-header .sidebar-logo-wrapper {
    display: flex;
}

.theme-container .theme-3-sidebar .theme-3-nav {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.theme-container .theme-3-sidebar .theme-3-header .sidebar-logo-wrapper:after, .theme-container .theme-3-sidebar .theme-3-header .sidebar-logo-wrapper:before {
    content: "";
    flex-shrink: 0;
    max-width: 60px;
    transition: max-width .2s ease-out;
    width: 5vw;
}

.theme-container .theme-3-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.theme-container {
    --animation-duration: 0.5s;
}

.theme-container {
    min-height: 100vh;
    transition: all .2s ease-out;
}

.theme-container .theme-3-sidebar {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
    width: 250px;
}


@media (min-width: 1200px) {
    .theme-container .theme-3-sidebar {
        width: 300px;
    }
}


.theme-container .theme-3-sidebar .theme-3-header {
    flex-shrink: 0;
    margin: 40px 0 8vh;
}

.logo-size--small .logo__img {
    max-height: 50px;
    max-width: 30vw;
    transition: none;
    transition: max-height .2s ease, max-width .2s ease;
}

@media (min-width: 768px) {
    .logo-size--small .logo__img {
        max-height: 60px;
    }
}

@media (min-width: 992px) {
    .logo-size--small .logo__img {
        max-height: 10vh;
        max-width: 120px;
    }
}

@media (min-width: 992px) {
    .theme-3-sidebar .block-theme3-nav {
        left: 0;
        position: absolute;
        top: 0;
        width: 250px;
    }
}


@media (min-width: 1200px) {
    .theme-3-sidebar .block-theme3-nav {
        width: 300px;
    }
}


@media (min-width: 576px) {
    .block-container {
        padding: 30px;
    }
}


@media (min-width: 768px) {
    .block-container {
        padding: 40px;
    }
}


@media (min-width: 1200px) {
    .block-container {
        padding: 60px;
    }
}


.theme-3-sidebar .theme-3-nav {
    height: 100%;
    position: relative;
}

.block-container--flushed-right {
    padding-right: 0 !important;
}

.block-container--flushed-left {
    padding-left: 0 !important;
}

.block-container--flushed-bottom {
    padding-bottom: 0 !important;
}

.block-container--flushed-top {
    padding-top: 0 !important;
}

.theme-container .theme-3-sidebar .theme-3-nav .sidebar-nav-wrapper {
    display: flex;
}

*, :after, :before {
    box-sizing: border-box;
}


.theme-container .theme-3-sidebar .theme-3-nav .sidebar-nav-wrapper:after, .theme-container .theme-3-sidebar .theme-3-nav .sidebar-nav-wrapper:before {
    content: "";
    flex-shrink: 0;
    max-width: 48px;
    transition: max-width .2s ease-out;
    width: 5vw;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .theme-container .theme-3-sidebar .theme-3-nav .sidebar-nav-wrapper:after, .theme-container .theme-3-sidebar .theme-3-nav .sidebar-nav-wrapper:before {
        max-width: 28px;
        width: 4vw;
    }
}

.arco-menu-light .arco-menu-item.arco-menu-selected {
    background-color: transparent !important;
}

.arco-menu-light .arco-menu-item:hover {
    background-color: transparent !important;
}

.theme-container .theme-3-sidebar .theme-3-footer.theme-3-footer_with-branding {
    margin-bottom: 56px;
}

.theme-container .theme-3-sidebar .theme-3-footer {
    flex-shrink: 0;
    margin: 4vh 0 30px;
}

.sidebar-footer-wrapper {
    display: flex;
}


.theme-container .theme-3-sidebar .theme-3-footer .sidebar-footer-wrapper:after, .theme-container .theme-3-sidebar .theme-3-footer .sidebar-footer-wrapper:before {
    content: "";
    flex-shrink: 0;
    max-width: 60px;
    transition: max-width .2s ease-out;
    width: 5vw;
}

.theme_3_footer-content .theme_3-footer__business-name {
    color: #999;
    font-size: 12px;
}

@media (min-width: 992px) {
    .theme_3_footer-content .theme_3-footer__business-name {
        max-width: 160px;
    }
}


.arco-menu-item {
    font-size: 15px;
}

.photo__photo-container {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
}

.photo__photo {
    transition: opacity .2s ease-out;
    width: 100%;
    will-change: opacity;
    margin-top: 20px;
}

.max-w-content {
    max-width: 1000px;
}

@media (min-width: 1600px) {
    .max-w-content {
        max-width: 1200px;
    }
}


@media (min-width: 768px) {
    .post__text.post__text--left {
        margin: 0 0 0 -25%;
        background-color: #fff;
    }
}

@media (min-width: 768px) {
    .post__text {
        padding: 15% 10%;
    }
}


@media (min-width: 992px) {
    .post__text.post__text--left, .post__text.post__text--right {
        margin: 0;
    }
}


.post__text {
    margin: -40px 10px 40px;
    overflow-wrap: break-word;
    padding: 40px 15px;
    text-align: center;
    z-index: 1;
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .post__text {
        padding: 15%;
        transition: margin .2s ease, background-color .2s ease;
    }
}

.post__title {
    color: #333333;
    margin-bottom: 0;
}

.post__divider {
    background-color: rgba(58, 58, 58, .2);
}

.post__divider {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 20px;
    width: 40px;
}

.post__date {
    margin-top: 15px;
    color: #666;
    font-size: 14px;
}

.post__blog-category {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.post {
    cursor: pointer;
}

@media (min-width: 992px) {
    .post:hover .post__text {
        background-color: #f7f7f7;
    }
}

@media (min-width: 992px) {
    .post:hover .post__text.post__text--left {
        margin-left: -90px;
        margin-right: 90px;
    }
}


@media (min-width: 768px) {
    .post__text.post__text--right {
        margin: 0 -25% 0 0;
    }
}


@media (min-width: 992px) {
    .post__text.post__text--right {
        margin: 0;
    }
}

@media (min-width: 992px) {
    .post:hover .post__text {
        background-color: #f7f7f7;
    }
}

@media (min-width: 992px) {
    .post:hover .post__text.post__text--right {
        margin-left: 90px;
        margin-right: -90px;
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .text-md-right {
        text-align: right !important;
    }
}

.post-header {
    margin-top: 30px;
    margin-left: 20px;
}

.post-header__date {
    color: #999;
    font-size: 14px;
    margin-bottom: 20px;
}

.post-header__title {
    color: #333;
    margin-top: 10px;
    margin-bottom: .5rem;
    margin-right: 20px;
}

.post-header__categories {
    margin-top: 25px;
    color: #999;
}
