body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.arco-menu-selected-label {
    background-color: #fff !important;
}

.arco-menu-light .arco-menu-item{
    color: #999 !important;
}

.arco-menu-light .arco-menu-item.arco-menu-selected{
    color: #333 !important;
}

.arco-menu-horizontal .arco-menu-item:not(:first-child) {
    margin-left: 20px !important;
}
