.landing-page__category-title {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #1e1e1e;
}

.landing-page__category-title:after {
    border-bottom: 1px solid #1e1e1e;
}

.landing-page__category-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 30px;
}

@media (min-width: 992px) {
    .landing-page__category-title:after {
        width: 40px;
    }
}

.landing-page__category-title {
    display: block;
    position: relative;
    padding-bottom: 9.5px;
    margin-bottom: 20.5px;
}

@media (min-width: 992px) {
    .landing-page__category-title {
        padding-bottom: 15px;
        margin-bottom: 24px;
    }
}

.landing-page__series-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #1e1e1e;
}

.landing-page__series-item {
    cursor: pointer;
}
